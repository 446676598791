import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ButtonWithUnderline from "../Button/ButtonWithUnderline";
const About = ({ headingLevel, className, hide, shadow }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={` ${className || "mb-14 p-4 md:mb-24 "}`}>
      <div className="container">
        <header className="mb-12 md:mb-14">
          <HeadingTag className="font-medium">The BayView Lifestyle</HeadingTag>
        </header>
        <div>
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            {/* START OF CARD 1 */}
            <div
              className={` rounded-[8px] shadow-lg ${` ${
                shadow === true && "bg-white"
              }`}`}
            >
              {hide !== 1 && (
                <Link
                  to="/about/"
                  className=" group flex flex-col font-normal text-typography-body no-underline hover:text-typography-body"
                >
                  <div className="overflow-hidden ">
                    <StaticImage
                      src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Staff.jpg"
                      loading="lazy"
                      width={746}
                      imgClassName="rounded-t-[8px]"
                      className="w-full scale-100 transform   blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div className="pt-2">
                    <h3 className="heading-four m-0 px-normal pt-[12px]">
                      About Our Staff
                    </h3>
                    <p className="m-0 p-normal">
                      Our caregivers are the best in the industry and embrace
                      BayView as home. Our staff treats each resident with
                      loving respect as if caring for their own family member.
                    </p>
                    <div className="block pt-0  pb-7 md:hidden md:p-0">
                      <ButtonWithUnderline
                        text="learn more"
                        className="mr-auto max-w-[180px] p-normal text-[16px] font-normal lg:ml-0"
                      />
                    </div>
                  </div>
                </Link>
              )}
            </div>
            {/* END OF CARD 1 */}
            {/* START OF CARD 2 */}
            <div
              className={` rounded-[8px] shadow-lg ${` ${
                shadow === true && "bg-white"
              }`}`}
            >
              {hide !== 2 && (
                <Link
                  to="/amenities/"
                  className=" group flex flex-col font-normal text-typography-body no-underline hover:text-typography-body"
                >
                  <div className="overflow-hidden ">
                    <StaticImage
                      src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Amenities.jpg"
                      loading="lazy"
                      width={746}
                      imgClassName="rounded-t-[8px]"
                      className="w-full scale-100 transform   blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div className="pt-2">
                    <h3 className="heading-four m-0 px-normal pt-[12px]">
                      The Amenities
                    </h3>
                    <p className="m-0 p-normal">
                      BayView was recently renovated and has a variety of
                      amenities, including an expansive outdoor space with a
                      garden, walking path, and covered indoor/outdoor patio
                      dining area.
                    </p>
                    <div className="block pt-0  pb-7 md:hidden md:p-0">
                      <ButtonWithUnderline
                        text="learn more"
                        className="mr-auto max-w-[180px] p-normal text-[16px] font-normal lg:ml-0"
                      />
                    </div>
                  </div>
                </Link>
              )}
            </div>
            {/* END OF CARD 2 */}
            {/* START OF CARD 3 */}
            <div
              className={` rounded-[8px] shadow-lg ${` ${
                shadow === true && "bg-white"
              }`}`}
            >
              {hide !== 3 && (
                <Link
                  to="/neighborhood/"
                  className=" group flex flex-col font-normal text-typography-body no-underline hover:text-typography-body"
                >
                  <div className="overflow-hidden ">
                    <StaticImage
                      src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Neighborhood.jpg"
                      loading="lazy"
                      width={746}
                      imgClassName="rounded-t-[8px]"
                      className="w-full scale-100 transform   blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div className="pt-2">
                    <h3 className="heading-four m-0 px-normal pt-[12px]">
                      The Neighborhood
                    </h3>
                    <p className="m-0 p-normal">
                      We’re located on the Point Loma peninsula, home to Liberty
                      Station, Sunset Cliffs Natural Park, and Cabrillo National
                      Monument.
                    </p>
                    <div className="block pt-0  pb-7 md:hidden md:p-0">
                      <ButtonWithUnderline
                        text="learn more"
                        className="mr-auto max-w-[180px] p-normal text-[16px] font-normal lg:ml-0"
                      />
                    </div>
                  </div>
                </Link>
              )}
            </div>
            {/* END OF CARD */}

            {/* {hide !== 4 && (
            <Link
              to=""
              className="group flex flex-col  font-normal text-typography-body no-underline hover:text-typography-body"
            >
              <div className="overflow-hidden rounded-3xl">
                <StaticImage
                  src="../../images/0.0 Repeated Modules/HarborView Lifestyle/FAQs.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                />
              </div>
              <div className="pt-6">
                <h3 className="heading-four">FAQs</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                  aliquam, purus sit amet luctus venenatis, lectus magna
                  fringilla urna, porttitor
                </p>
              </div>
            </Link>
          )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
