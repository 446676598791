import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const NeighborhoodSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <section className="bg-tertiary-50 relative mb-20 pt-12 md:mb-20 md:py-[2rem] xl:py-[8rem]">
      <div className="absolute bottom-0 left-0 hidden  md:block">
        <StaticImage
          src="../../images/3.0 Neighborhood/1.0 Neighborhood.jpg"
          loading="eager"
        />
      </div>
      <div className="container relative lg:py-24">
        <div className="grid w-full gap-y-8">
          <div className=" mx-auto grid max-w-[560px] grid-cols-1  text-center md:ml-0 lg:gap-y-[25px] md:text-left">
            <h1 className="md:text-white mb-0 lg:max-w-[375px]">{sectionTitle}</h1>
            <p className="md:text-white">{sectionDesc}</p>
            <div className="mx-auto block md:ml-0 md:inline-block">
              <ButtonSolid className="" modal="modal-contact" text="Contact" />
            </div>
          </div>
          <div className="-mx-4 md:mx-0 md:hidden">
            <StaticImage
              src="../../images/3.0 Neighborhood/1.0 Neighborhood-mobile.jpg"
              loading="eager"
              className="h-full w-full"
              height={350}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeighborhoodSection1;
