import React from "react";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import ButtonGhost from "../../components/Button/ButtonGhost";

// import PortableText from "../../components/Blog/portableText";
import tw from "twin.macro";
import styled from "@emotion/styled";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-2 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const NeighborhoodSection2 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  title4,
  description4,
  title5,
  description5,
}) => {
  return (
    <>
      <section className={` ${className || ""}`}>
        <div className="container">
          <div className="grid gap-y-10 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-12 md:mb-16">
                <StyledContent></StyledContent>
                <h2>{title1}</h2>
                <p>
                  BayView Senior Assisted Living was originally a convent to the
                  neighboring St. Agnes parish and has been a staple in this San
                  Diego-area community for decades. It has a cozy yet refined
                  atmosphere, and we’re proud to be an integral part of the
                  Point Loma community.
                </p>
              </div>
              <div className="mb-12 md:mb-16">
                <StyledContent>
                  <h3>{title2}</h3>
                  <p>
                    There are plenty of things to see and do for residents and
                    their families. You can:
                  </p>
                  <div className="md:pr pl-0 pr-[15px] md:col-end-4 ">
                    {/* left list */}

                    <ul className="grid gap-y-3 pl-0 md:gap-y-[5px]">
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Head to Cabrillo National Monument for unbeatable
                          views
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>Shop local at Liberty Public Market</span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Appreciate art at the NTC Arts District within Liberty
                          Station
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Take a tour of the USS Midway in nearby downtown San
                          Diego
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Find a bench on the bayfront and watch the boats
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-500 stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Catch an unbelievable sunset at Sunset Cliffs Natural
                          Park
                        </span>
                      </li>
                    </ul>
                    <p>
                      Point Loma is also home to Point Loma Nazarene University,
                      which has a picturesque campus, arguably the most
                      beautiful one in all of San Diego.
                    </p>
                  </div>
                  <h3>History of the Area</h3>
                  <p>
                    Point Loma holds a special place in California’s history
                    because it’s where the first European expedition came ashore
                    in the state. Juan Rodríguez Cabrillo, a Portuguese
                    navigator leading an expedition for the Spanish crown,
                    arrived there in 1542. Cabrillo National Monument honors
                    that landing, and Point Loma is often described as “where
                    California began.”
                  </p>
                </StyledContent>
                <ButtonSolid
                  className="w-[140px]"
                  modal="modal-contact"
                  text="Contact"
                />
              </div>
            </div>

            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <div className="md: bg-tertiary-50 sticky top-32 rounded-3xl p-10 md:rounded-3xl md:bg-secondary-400 md:p-10">
                <div>
                  <h3>Schedule a Tour</h3>
                  <StyledContent>
                    <p>Our doors are always open and we’d love to meet you!</p>
                  </StyledContent>
                  <ButtonSolid modal="modal-contact" text="Book a Visit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeighborhoodSection2;
